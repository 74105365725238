var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    { attrs: { align: "center", justify: "end" } },
    [
      _c(
        "v-btn",
        {
          attrs: { icon: "" },
          nativeOn: {
            click: function ($event) {
              return _vm.searchBegin.apply(null, arguments)
            },
          },
        },
        [_c("v-icon", [_vm._v("search")])],
        1
      ),
      _c(
        "div",
        {
          staticClass: "searching",
          class: { "mr-2": _vm.searching, "searching--closed": !_vm.searching },
        },
        [
          _c("v-text-field", {
            ref: "search",
            attrs: {
              "append-icon": "close",
              label: "Search",
              "hide-details": "",
              "single-line": "",
              color: "white",
            },
            on: { "click:append": _vm.clearSearch, blur: _vm.clearSearch },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }