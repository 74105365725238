
import { Component, Vue } from 'vue-property-decorator';
import packageJSON from '@/../package.json';

@Component
export default class FooterComponent extends Vue {
	get year() {
		return new Date().getFullYear();
	}

	get version() {
		return packageJSON.version;
	}
}
