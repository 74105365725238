import { RouteConfig } from 'vue-router';

import { auth, hasAccount } from '@/router/middleware';
import { DATA_OPERATIONS } from '@/constants/router/paths-prefixes';
import { API_TO_STORAGE } from '@/constants/data-operations/names';
import { RUNS, CONFIGURATIONS } from '@/constants/data-operations/status';
import {
	API_TO_STORAGE_RUNS_LISTING,
	API_TO_STORAGE_RUNS_ITEM,
	API_TO_STORAGE_CONFIGURATIONS_LISTING,
	API_TO_STORAGE_CONFIGURATIONS_ITEM,
} from '@/constants/router/routes-names';

const displayName = 'Api to Storage';

export const apiToStorageRoutes: RouteConfig[] = [
	/**
	 * RUNS
	 */
	{
		path: `/${DATA_OPERATIONS}/${API_TO_STORAGE.url}/${RUNS}`,
		name: API_TO_STORAGE_RUNS_LISTING,
		meta: { middleware: [auth, hasAccount], displayName },
		component: () =>
			import(
				/* webpackChunkName: "/data-operations/api-to-storage/runs/listing" */ '@/views/data-operations/api-to-storage/runs/ListingView.vue'
			),
	},
	{
		path: `/${DATA_OPERATIONS}/${API_TO_STORAGE.url}/${RUNS}/:id`,
		name: API_TO_STORAGE_RUNS_ITEM,
		meta: { middleware: [auth, hasAccount], displayName },
		component: () =>
			import(
				/* webpackChunkName: "/data-operations/api-to-storage/run/item" */ '@/views/data-operations/api-to-storage/runs/ItemView.vue'
			),
	},

	/**
	 * CONFIGURATIONS
	 */
	{
		path: `/${DATA_OPERATIONS}/${API_TO_STORAGE.url}/${CONFIGURATIONS}`,
		name: API_TO_STORAGE_CONFIGURATIONS_LISTING,
		meta: { middleware: [auth, hasAccount], displayName },
		component: () =>
			import(
				/* webpackChunkName: "/data-operations/api-to-storage/configurations/listing" */ '@/views/data-operations/api-to-storage/configurations/ListingView.vue'
			),
	},
	{
		path: `/${DATA_OPERATIONS}/${API_TO_STORAGE.url}/${CONFIGURATIONS}/:id`,
		name: API_TO_STORAGE_CONFIGURATIONS_ITEM,
		meta: { middleware: [auth, hasAccount], displayName },
		component: () =>
			import(
				/* webpackChunkName: "/data-operations/api-to-storage/configurations/item" */ '@/views/data-operations/api-to-storage/configurations/ItemView.vue'
			),
	},
];
