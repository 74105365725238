import { IEasyFirestoreModule } from 'vuex-easy-firestore/types/declarations';
import { COLLECTION_REF, STATE_PROP_NAME, SYNC } from '@/constants/store/easy-firestore';

export const apiToStorageConfArchive: IEasyFirestoreModule = {
	firestorePath: 'api-to-storage-conf-archive',
	firestoreRefType: COLLECTION_REF,
	moduleName: 'apiToStorageConfArchive',
	statePropName: STATE_PROP_NAME,
	sync: SYNC,
};
