import { ActionTree } from 'vuex';
import {
	createUserWithEmailAndPassword,
	getAuth,
	GoogleAuthProvider,
	signInWithEmailAndPassword,
	signInWithPopup,
	signOut,
	User,
} from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';
import router from '@/router';
import store from '@/store';
import { users } from '@/store/modules/easy-firestore/users';
import { connectionsHistory } from '@/store/modules/easy-firestore/connections-history';
import { RootState, UserState } from '@/types';
import { HOME, SIGN_IN } from '@/constants/router/routes-names';

const dispatchUser = (user: User | null) => {
	if (!user) return;

	store.dispatch(`${users.moduleName}/set`, {
		id: user!.uid,
		disabled: false,
		deleted: false,
		displayName: user!.displayName,
		photoURL: user!.photoURL,
		email: user!.email,
	});

	store.dispatch(`${connectionsHistory.moduleName}/insert`, {
		userId: user!.uid,
		date: Timestamp.now(),
	});
};

export const actions: ActionTree<UserState, RootState> = {
	signIn({ commit }, { email, password }) {
		return new Promise(function (resolve, reject) {
			signInWithEmailAndPassword(getAuth(), email, password)
				.then(async (user) => {
					await commit('setUser', user.user);
					commit('setIsAuthenticated', true);
					dispatchUser(user.user);
					resolve(user.user);
				})
				.catch((e) => {
					reject(e);
				});
		});
	},
	googleSignIn({ commit }) {
		const provider = new GoogleAuthProvider();

		return new Promise(function (resolve, reject) {
			signInWithPopup(getAuth(), provider)
				.then(async (user) => {
					await commit('setUser', user.user);
					commit('setIsAuthenticated', true);
					dispatchUser(user.user);
					resolve(user.user);
				})
				.catch((e) => {
					reject(e);
				});
		});
	},
	signUp({ commit }, { email, password }) {
		createUserWithEmailAndPassword(getAuth(), email, password)
			.then(async (user) => {
				await commit('setUser', user.user);
				commit('setIsAuthenticated', true);
				dispatchUser(user.user);
				await router.push({ name: HOME });
			})
			.catch(async () => {
				await commit('setUser', null);
				commit('setIsAuthenticated', false);
				await router.push({ name: SIGN_IN });
			});
	},
	async alreadySignedIn({ commit }, payload) {
		await commit('setUser', payload);
		await commit('setIsAuthenticated', true);
	},
	signOut: async ({ commit }) => {
		commit('setIsAuthenticated', false);
		await commit('setUser', null);
		await router.push({ name: SIGN_IN });
		signOut(getAuth());
	},
};
