import { MutationTree } from 'vuex';
import { getIdTokenResult } from 'firebase/auth';
import { UserState } from '@/types';

export const mutations: MutationTree<UserState> = {
	setUser(state, payload) {
		return new Promise(function (resolve, reject) {
			if (payload) {
				state.user = payload;

				getIdTokenResult(payload)
					.then((idTokenResult) => {
						if (!state.user) return;

						state.user = {
							...state.user,
							accounts: idTokenResult.claims.accounts ? idTokenResult.claims.accounts : [],
							studioRoles: idTokenResult.claims.studioRoles ? idTokenResult.claims.studioRoles : [],
						};

						resolve();
					})
					.catch((error) => {
						console.error(error);
						reject();
					});
			} else {
				state.user = null;
			}
		});
	},
	setIsAuthenticated(state, payload) {
		state.isAuthenticated = payload;
	},
};
