import Vue from 'vue';
import vueMoment from 'vue-moment';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import App from './App.vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import { initFirebase } from '@/plugins/firebase';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import vueDebounce, { PluginConfig } from 'vue-debounce';

import 'moment-duration-format';
import '@/plugins/vue-axios';
import '@/plugins/vue-gtm';
import moment from 'moment';

if (process.env.NODE_ENV !== 'development') {
	Sentry.init({
		dsn: process.env.VUE_APP_SENTRY_DSN,
		environment: process.env.NODE_ENV,
		integrations: [new VueIntegration({ Vue, attachProps: true })],
	});
}

initFirebase().catch((error) => {
	console.error('ERROR', error);
});

Vue.config.productionTip = false;

Vue.use(vueMoment);
Vue.use(TiptapVuetifyPlugin, {
	vuetify,
	iconsGroup: 'mdi',
});
Vue.use<PluginConfig>(vueDebounce, { lock: true, defaultTime: '400ms', listenTo: 'keyup' });

let app: Vue;

const createApp = () => {
	if (!app) {
		app = new Vue({
			store,
			router,
			vuetify,
			render: (h) => h(App),
		}).$mount('#app');
	}
};

onAuthStateChanged(getAuth(), async (user) => {
	if (user) {
		if (moment(user.metadata.lastSignInTime as string).isBefore(new Date(), 'day')) {
			await store.dispatch('user/signOut');
		} else {
			await store.dispatch('accounts/fetchAndAdd');
			await store.dispatch('schemas/fetchAndAdd');
			await store.dispatch('user/alreadySignedIn', user);
			createApp();
		}
	} else {
		createApp();
	}
});
