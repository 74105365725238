
import { Component, Watch, Vue } from 'vue-property-decorator';
import AccountSelector from '@/components/app/AccountsSelector.vue';
import AppBar from '@/components/app/app-bar/AppBar.vue';
import SideBar from '@/components/app/SideBar.vue';

import { State, Getter } from 'vuex-class';

interface Drawer {
	permanent: boolean;
	mini: boolean;
}

@Component({
	components: { AccountSelector, AppBar, SideBar },
})
export default class App extends Vue {
	showNavigation: boolean = true;

	@State((state) => state.user.isAuthenticated) isAuthenticated!: boolean;
	@Getter('user/accounts') accounts!: string[];

	get showLayout() {
		return this.isAuthenticated && this.accounts.length > 0;
	}
}
