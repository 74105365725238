
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import { mdiTextBoxMultipleOutline } from '@mdi/js';
import { User, AccountId } from '@/types';
import { LOGOUT, PROFILE } from '@/constants/router/routes-names';

import SearchMenu from './sub-components/SearchMenu.vue';

@Component({
	components: { SearchMenu },
})
export default class AppBar extends Vue {
	@State((state) => state.user.isAuthenticated) isAuthenticated!: boolean;
	@State((state) => state.user.user) user!: User;

	@State((state) => state.accounts.data) accounts!: Account[];
	@Getter('user/accounts') accountIds!: AccountId[];

	selectedAccount: Account = JSON.parse(localStorage.vuex).filters.filteredAccounts[0];

	@Watch('selectedAccount')
	onSelectedAccountChange(selectedAccount: Account) {
		this.$store.dispatch('filters/updateFilteredAccounts', [selectedAccount]);
	}

	get accountsAvalaible(): Account[] {
		return this.accountIds.map((id) => {
			return this.accounts[id];
		});
	}

	get documentationIcon() {
		return mdiTextBoxMultipleOutline;
	}

	get tabName() {
		let tab;
		if (this.$route.path.includes('data-operations')) tab = 'Data Operations';
		if (this.$route.path.includes('data-quality')) tab = 'Data Quality';
		if (this.$route.path.includes('data-models')) tab = 'Data Models';
		return tab || null;
	}

	get routeName() {
		return this.$route?.meta?.displayName || null;
	}

	get userSettingsItems() {
		return [
			{
				title: 'Profile',
				link: { name: PROFILE },
				icon: 'account_circle',
			},
			{
				title: 'Logout',
				link: { name: LOGOUT },
				icon: 'exit_to_app',
			},
		];
	}
}
