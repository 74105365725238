import { IEasyFirestoreModule } from 'vuex-easy-firestore/types/declarations';
import { COLLECTION_REF, STATE_PROP_NAME, SYNC } from '@/constants/store/easy-firestore';

export const getTableToTableRunsLight: IEasyFirestoreModule = {
	firestorePath: 'table-to-tables-runs-light',
	firestoreRefType: COLLECTION_REF,
	moduleName: 'getTableToTableRunsLight',
	statePropName: STATE_PROP_NAME,
	sync: SYNC,
};
