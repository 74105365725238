import { DataWorkflowsType } from '@/types';

export const RUNS: DataWorkflowsType = 'runs';
export const JOBS: DataWorkflowsType = 'jobs';
export const CONFIGURATIONS: DataWorkflowsType = 'configurations';
export const STATUS: DataWorkflowsType = 'status';
export const EXPECTATIONS: DataWorkflowsType = 'expectations';
export const FRESHNESS: DataWorkflowsType = 'freshness';

export default {
	RUNS,
	JOBS,
	CONFIGURATIONS,
	STATUS,
	EXPECTATIONS,
	FRESHNESS,
};
