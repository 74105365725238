import { RouteConfig } from 'vue-router';

import { auth, hasAccount, remoteConfig } from '@/router/middleware';
import { DATA_OPERATIONS } from '@/constants/router/paths-prefixes';
import { XML_CONVERSION } from '@/constants/data-operations/names';
import { CONFIGURATIONS } from '@/constants/data-operations/status';
import {
	XML_CONVERSION_CONFIGURATIONS_LISTING,
	XML_CONVERSION_CONFIGURATIONS_ITEM,
} from '@/constants/router/routes-names';

const displayName = 'XML Conversion';

export const XMLConversionRoutes: RouteConfig[] = [
	/**
	 * CONFIGURATIONS
	 */
	{
		path: `/${DATA_OPERATIONS}/${XML_CONVERSION.url}/${CONFIGURATIONS}`,
		name: XML_CONVERSION_CONFIGURATIONS_LISTING,
		meta: { middleware: [auth, hasAccount, remoteConfig.canAccessXMLConversion], displayName },
		component: () =>
			import(
				/* webpackChunkName: "/data-operations/xml-conversion/configurations/listing" */ '@/views/data-operations/xml-conversion/configurations/ListingView.vue'
			),
	},
	{
		path: `/${DATA_OPERATIONS}/${XML_CONVERSION.url}/${CONFIGURATIONS}/:id`,
		name: XML_CONVERSION_CONFIGURATIONS_ITEM,
		meta: { middleware: [auth, hasAccount, remoteConfig.canAccessXMLConversion], displayName },
		component: () =>
			import(
				/* webpackChunkName: "/data-operations/xml-conversion/configurations/item" */ '@/views/data-operations/xml-conversion/configurations/ItemView.vue'
			),
	},
];
