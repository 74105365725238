
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class SearchMenu extends Vue {
	searching: boolean = false;
	search: string = '';

	searchBegin() {
		this.searching = true;
		setTimeout(() => (this.$refs.search as HTMLInputElement).focus(), 200);
	}

	clearSearch() {
		this.searching = false;
		this.search = '';
	}
}
