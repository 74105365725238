import { GetterTree } from 'vuex';
import { Account, EnvFilter, FilterState, RootState } from '@/types';
import moment from 'moment';
import { Environments } from '@/constants/data-operations/filters';
import { TaskCriticalities } from '@/constants/data-quality/filters';

enum MinDateFilterFields {
	DagExecutionDate = 'dag_execution_date',
	ExecutionDate = 'execution_date',
	LastModified = 'last_modified',
}

const getAccountsFilter = (filteredAccounts: Account[], formattedFilteredAccounts: string[], rootState: RootState) => {
	return filteredAccounts.length > 0
		? ['account', '==', formattedFilteredAccounts[0]]
		: ['account', 'in', rootState.user.user?.accounts];
};

const getEnvFilterSelected = (envFilterSelected: EnvFilter) => {
	return ['environment', '==', envFilterSelected.value];
};

const getMinDateFilter = (minDateFilter: string, field: MinDateFilterFields) => {
	return minDateFilter ? [field, '>=', minDateFilter] : [field, '>=', '2019-01-01T00:00:00.000Z'];
};

export const getters: GetterTree<FilterState, RootState> = {
	filteredAccounts(state) {
		return state.filteredAccounts;
	},
	periodFiltered(state) {
		//Compute the date array from minDateFilter to Now to build chart
		let periodFiltered = [];
		var i;
		for (i = 0; i <= state.dateFilterSelected.value; i++) {
			periodFiltered.push(
				moment()
					.utc()
					.startOf('day')
					.subtract(state.dateFilterSelected.value - i, 'days')
					.toISOString()
			);
		}
		return periodFiltered;
	},
	whereStatusFilter(state, getters, rootState) {
		const { filteredAccounts, minDateFilter, envFilterSelected } = state;
		const formattedFilteredAccounts = filteredAccounts.map((account) => account.id);

		let filters = [
			getAccountsFilter(filteredAccounts, formattedFilteredAccounts, rootState),
			getMinDateFilter(minDateFilter, MinDateFilterFields.LastModified),
		];

		if (envFilterSelected.value !== 'ALL') filters.push(getEnvFilterSelected(envFilterSelected));

		return filters;
	},
	whereRunsFilter(state, getters, rootState) {
		const { filteredAccounts, minDateFilter, envFilterSelected, runStatusFilterSelected } = state;
		const formattedFilteredAccounts = filteredAccounts.map((account) => account.id);

		let filters = [
			getAccountsFilter(filteredAccounts, formattedFilteredAccounts, rootState),
			getMinDateFilter(minDateFilter, MinDateFilterFields.DagExecutionDate),
		];

		if (envFilterSelected.value !== 'ALL') filters.push(getEnvFilterSelected(envFilterSelected));
		if (runStatusFilterSelected.value !== 'ALL' && runStatusFilterSelected.value !== 'NOT_SUCCESS') {
			filters.push(['status', '==', runStatusFilterSelected.value]);
		}

		if (runStatusFilterSelected.value === 'NOT_SUCCESS') {
			filters.push(['status', 'in', ['RUNNING', 'FAILED', 'NO_MATCH', 'CHECKED']]);
		}

		return filters;
	},
	whereConfFilter(state, getters, rootState) {
		const { filteredAccounts, envFilterSelected, confActivatedFilterSelected } = state;
		const formattedFilteredAccounts = filteredAccounts.map((account) => account.id);

		let filters = [getAccountsFilter(filteredAccounts, formattedFilteredAccounts, rootState)];

		if (envFilterSelected.value !== 'ALL') filters.push(getEnvFilterSelected(envFilterSelected));
		if (confActivatedFilterSelected.value === 'ALL') {
			// @ts-ignore
			filters.push(['archived', '==', false]);
		} else {
			if (confActivatedFilterSelected.label === 'Archived') {
				// @ts-ignore
				filters.push(['archived', '==', confActivatedFilterSelected.value]);
			} else {
				// @ts-ignore
				filters.push(['archived', '==', false]);
				// @ts-ignore
				filters.push(['activated', '==', confActivatedFilterSelected.value]);
			}
		}

		return filters;
	},
	whereExpectationsFilters(state, getters, rootState) {
		const { envFilterSelected, expectations, filteredAccounts, minDateFilter } = state;
		const formattedFilteredAccounts = filteredAccounts.map((account) => account.id);

		let filters: any = [
			getAccountsFilter(filteredAccounts, formattedFilteredAccounts, rootState),
			getMinDateFilter(minDateFilter, MinDateFilterFields.ExecutionDate),
		];

		if (envFilterSelected.value !== Environments.All) filters.push(getEnvFilterSelected(envFilterSelected));

		if (expectations.taskCriticality.value !== TaskCriticalities.All)
			filters.push(['task_criticality', '==', expectations.taskCriticality.value]);

		if (expectations.passed.value !== 'ALL')
			filters.push(['expectation_result.passed', '==', expectations.passed.value]);

		return filters;
	},
};
