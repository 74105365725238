var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "sidebar" },
    [
      _c(
        "div",
        [
          _c("router-link", { staticClass: "item", attrs: { to: "/" } }, [
            _c(
              "div",
              { staticClass: "icon-box" },
              [
                _c("project-overview-icon", {
                  attrs: { isActive: _vm.isCurrentPage("home") },
                }),
              ],
              1
            ),
            _c(
              "p",
              {
                staticClass: "name",
                class: { "text-selected": _vm.isCurrentPage("home") },
              },
              [_vm._v("Project Overview")]
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "item",
              on: {
                click: function ($event) {
                  _vm.dataOperationIsOpen = !_vm.dataOperationIsOpen
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "icon-box" },
                [_c("data-operations-icon")],
                1
              ),
              _c("p", { staticClass: "name" }, [_vm._v("Data Operation")]),
              _c(
                "v-icon",
                {
                  staticClass: "arrow",
                  class: { rotate: !_vm.dataOperationIsOpen },
                  attrs: { color: "white" },
                },
                [_vm._v("expand_more")]
              ),
            ],
            1
          ),
          _vm.dataOperationIsOpen
            ? _vm._l(_vm.dataOperations, function (item) {
                return _c(
                  "div",
                  { key: item.name },
                  [
                    !item.hasOwnProperty("displayRule") || item.displayRule
                      ? _c(
                          "router-link",
                          {
                            staticClass: "item sub-item",
                            attrs: { to: item.link },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "icon-box" },
                              [
                                item.iconPath
                                  ? _c(item.iconPath, {
                                      tag: "component",
                                      attrs: {
                                        isActive: _vm.isCurrentPage(
                                          item.link.base
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "p",
                              {
                                staticClass: "name",
                                class: {
                                  "text-selected": _vm.isCurrentPage(
                                    item.link.base
                                  ),
                                },
                              },
                              [_vm._v(_vm._s(item.name))]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              })
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "item",
              on: {
                click: function ($event) {
                  _vm.dataQualityIsOpen = !_vm.dataQualityIsOpen
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "icon-box" },
                [_c("data-quality-icon")],
                1
              ),
              _c("p", { staticClass: "name" }, [_vm._v("Data Quality")]),
              _c(
                "v-icon",
                {
                  staticClass: "arrow",
                  class: { rotate: !_vm.dataQualityIsOpen },
                  attrs: { color: "white" },
                },
                [_vm._v("expand_more")]
              ),
            ],
            1
          ),
          _vm.dataQualityIsOpen
            ? _vm._l(_vm.dataQuality, function (item) {
                return _c(
                  "div",
                  { key: item.name },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "item sub-item",
                        attrs: { to: item.link },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "icon-box" },
                          [
                            item.iconPath
                              ? _c(item.iconPath, {
                                  tag: "component",
                                  attrs: {
                                    isActive: _vm.isCurrentPage(item.link.base),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "name",
                            class: {
                              "text-selected": _vm.isCurrentPage(
                                item.link.base
                              ),
                            },
                          },
                          [_vm._v(_vm._s(item.name))]
                        ),
                      ]
                    ),
                  ],
                  1
                )
              })
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "item white--text",
              on: {
                click: function ($event) {
                  _vm.dataModelsIsOpen = !_vm.dataModelsIsOpen
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "icon-box" },
                [_c("data-models-icon")],
                1
              ),
              _c("p", { staticClass: "name" }, [_vm._v("Data Models")]),
              _c(
                "v-icon",
                {
                  staticClass: "arrow",
                  class: { rotate: !_vm.dataModelsIsOpen },
                  attrs: { color: "white" },
                },
                [_vm._v("expand_more")]
              ),
            ],
            1
          ),
          _vm.dataModelsIsOpen
            ? [_c("tree-view", { staticClass: "tree-view mb-6" })]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "settings" },
        _vm._l(_vm.settings, function (item) {
          return _c(
            "div",
            { key: item.name },
            [
              !item.hasOwnProperty("displayRule") || item.displayRule
                ? _c(
                    "router-link",
                    { staticClass: "item", attrs: { to: item.link } },
                    [
                      _c(
                        "div",
                        { staticClass: "icon-box" },
                        [
                          item.iconPath
                            ? _c(item.iconPath, {
                                tag: "component",
                                attrs: {
                                  isActive: _vm.isCurrentPage(item.link.name),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "name",
                          class: {
                            "text-selected": _vm.isCurrentPage(item.link.name),
                          },
                        },
                        [_vm._v(_vm._s(item.name))]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
      _c("footer-content"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }