import { RouteConfig } from 'vue-router';

import { auth, hasAccount, superAdmin } from '@/router/middleware';
import { SETTINGS } from '@/constants/router/paths-prefixes';
import { PROFILE, USERS, ACCOUNTS } from '@/constants/router/routes-names';

export const settingsRoutes: RouteConfig[] = [
	{
		path: `/${SETTINGS}/profile`,
		name: PROFILE,
		meta: { middleware: [auth, hasAccount], displayName: 'Profile' },
		component: () => import(/* webpackChunkName: "settings/profile" */ '@/views/settings/Profile.vue'),
	},
	{
		path: `/${SETTINGS}/users`,
		name: USERS,
		meta: { middleware: [auth, superAdmin], displayName: 'Users' },
		component: () => import(/* webpackChunkName: "settings/users" */ '@/views/settings/Users.vue'),
	},
	{
		path: `/${SETTINGS}/accounts`,
		name: ACCOUNTS,
		meta: { middleware: [auth, superAdmin], displayName: 'Account' },
		component: () => import(/* webpackChunkName: "settings/accounts" */ '@/views/settings/Accounts.vue'),
	},
];
