var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    [
      _vm.showLayout
        ? [
            _vm.$store.getters["filters/filteredAccounts"] &&
            _vm.$store.getters["filters/filteredAccounts"].length === 0
              ? _c("account-selector")
              : [
                  _c("app-bar"),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c("side-bar"),
                      _c(
                        "v-main",
                        { staticClass: "pa-0" },
                        [
                          _c(
                            "transition",
                            { attrs: { name: "fade", mode: "out-in" } },
                            [_c("router-view", { staticClass: "page" })],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
          ]
        : _c(
            "v-main",
            [
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [_c("router-view")],
                1
              ),
            ],
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }