var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "p",
    { staticClass: "ma-0", staticStyle: { "font-size": "11px" } },
    [
      _vm._v(" © " + _vm._s(_vm.year) + ", Tailer by "),
      _c(
        "a",
        { attrs: { href: "https://www.fashiondata.io/", target: "_blank" } },
        [_vm._v("Fashion Data")]
      ),
      _vm._v(" | v" + _vm._s(_vm.version) + " "),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }