var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "18",
        viewBox: "0 0 24 18",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M0.175608 6.31393L11.5095 17.0667C11.6379 17.187 11.814 17.2534 11.9996 17.2534C12.1851 17.2534 12.3659 17.187 12.4896 17.0667L23.8235 6.31393C24.0376 6.11065 24.0614 5.79952 23.8711 5.5755L19.3889 0.232313C19.2652 0.0871173 19.0654 0 18.856 0H5.14785C4.93849 0 4.73865 0.0871173 4.61494 0.232313L0.132785 5.5755C-0.0575402 5.79952 -0.0432658 6.10651 0.175608 6.31393ZM16.9385 6.50061H21.8156L13.7601 14.142L16.9385 6.50061ZM7.73627 4.68359L6.29932 1.14497H10.6911L7.73627 4.68359ZM13.308 1.14497H17.6998L16.2628 4.68359L13.308 1.14497ZM7.05586 6.48816L10.2343 14.1296L2.18354 6.49646L7.05586 6.48816ZM22.0202 5.3266H17.4143L18.9131 1.6096L22.0202 5.3266ZM5.07172 1.63034L6.57053 5.34734H1.97894L5.07172 1.63034Z",
          fill: "#FDFDFD",
        },
      }),
      _c("path", {
        attrs: {
          d: "M15.8202 5.56308L12.3706 1.47686C12.1707 1.29018 11.8424 1.29018 11.6426 1.47686L8.19293 5.56308C8.10729 5.6419 8.05971 5.73316 8.04543 5.82858C8.02164 5.8908 8.02164 5.95718 8.04543 6.02355L11.6664 15.0672C11.7663 15.341 12.1898 15.341 12.2897 15.0672L15.9534 6.02355C15.9677 5.98622 15.9725 5.94888 15.9725 5.91155C15.9772 5.78709 15.9296 5.66264 15.8202 5.56308Z",
          fill: "#717DC6",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }