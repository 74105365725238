var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        width: "19",
        height: "23",
        viewBox: "0 0 19 23",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M18.8496 8.70866L18.8374 8.67477C18.7276 8.41215 18.5649 8.17072 18.3576 7.96317L10.9332 0.597238C10.5429 0.211786 10.0347 0 9.50203 0C8.96533 0 8.45709 0.211786 8.07083 0.597238L0.674941 7.93352L0.64648 7.95893C0.435052 8.16648 0.272416 8.40792 0.162636 8.67477C0.0528568 8.94162 0 9.22965 0 9.52615V22.3138C0 22.6908 0.296812 23 0.658678 23H18.3413C18.7032 23 19 22.6908 19 22.3138V9.53039C19 9.24236 18.9512 8.9628 18.8496 8.70866ZM17.6867 9.53039V21.6276H1.32142V9.53039C1.32142 9.41179 1.34175 9.30589 1.37834 9.21694C1.419 9.12376 1.47593 9.03481 1.55724 8.95433L8.97346 1.59263C9.11577 1.45285 9.3028 1.37238 9.50203 1.37238C9.70126 1.37238 9.8883 1.44862 10.0306 1.59263L17.4509 8.95433C17.5322 9.03481 17.5932 9.12376 17.6339 9.22118L17.6379 9.23389C17.6704 9.3186 17.6867 9.42026 17.6867 9.53039Z",
          fill: _vm.isActive ? "#343A60" : "#FDFDFD",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12.7344 16.3242V13.3337C12.7344 12.7831 12.527 12.2663 12.153 11.8766C11.7789 11.487 11.2829 11.2709 10.7543 11.2709H8.24157C7.71707 11.2709 7.22103 11.487 6.8429 11.8766C6.46884 12.2663 6.26147 12.7831 6.26147 13.3337V16.3242",
          fill: "#717DC6",
        },
      }),
      _c("path", {
        attrs: {
          d: "M6.26965 15.8963V16.3453C6.26965 16.8959 6.47701 17.4127 6.85108 17.8024C7.22921 18.1921 7.72525 18.4081 8.24975 18.4081H10.7625C11.2911 18.4081 11.7871 18.1921 12.1612 17.8024C12.5352 17.4127 12.7426 16.8959 12.7426 16.3453V15.8963",
          fill: "#717DC6",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }