
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import { Account, AccountId, User } from '@/types';

@Component
export default class AccountSelector extends Vue {
	@State((state) => state.accounts.data) accounts!: Account[];
	@State((state) => state.user.user) user!: User;

	@Getter('user/accounts') userAccounts!: AccountId[];

	@Watch('selectedAccount')
	onSelectedAccountChange(selectedAccount: Account) {
		this.$store.dispatch('filters/updateFilteredAccounts', [selectedAccount]).then(() => {
			this.$router.push('/');
		});
	}

	isLoading: boolean = true;
	selectedAccount: string = JSON.parse(localStorage.vuex).filters.filteredAccounts;

	getAccounts() {
		let accounts: AccountId[] = [];

		this.userAccounts.forEach((id) => {
			const element = this.accounts[id];
			if (element) accounts.push(element);
		});

		return accounts;
	}
}
