var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "27",
        height: "27",
        viewBox: "0 0 27 27",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M11.7223 26.1936L4.86582 19.7008C4.15573 19.0278 4.15573 17.9411 4.86582 17.2681L11.7165 10.7753C12.4266 10.1023 13.5732 10.1023 14.2833 10.7753L21.134 17.2681C21.8441 17.9411 21.8441 19.0278 21.134 19.7008L14.2892 26.1936C13.5791 26.8666 12.4266 26.8666 11.7223 26.1936Z",
          fill: "#717DC6",
        },
      }),
      _c("path", {
        attrs: {
          d: "M15.7645 1.29283L13.5668 0.124495C13.2143 -0.0414982 12.7927 -0.0414982 12.4333 0.124495L10.2355 1.28645C9.90381 1.48436 9.68956 1.82273 9.66882 2.18664V4.51054C9.68956 4.87445 9.90381 5.21282 10.2355 5.41073L12.4333 6.57268C12.7858 6.73868 13.2074 6.73868 13.5668 6.57268L15.7645 5.41073C16.0963 5.21282 16.3036 4.88083 16.3312 4.51692V2.19302C16.3036 1.82273 16.0963 1.49074 15.7645 1.29283ZM15.1218 4.47223L13.0069 5.58949L10.8852 4.47223V2.23771L13 1.12045L15.1218 2.23771V4.47223Z",
          fill: "#FDFDFD",
        },
      }),
      _c("path", {
        attrs: {
          d: "M22.109 8.49437C22.4614 8.66037 22.883 8.66037 23.2424 8.49437L25.4402 7.33242C25.7719 7.13451 25.9792 6.80252 26.0069 6.43861V4.10833C25.9862 3.74442 25.7719 3.40605 25.4402 3.20814L23.2424 2.04619C22.8899 1.88019 22.4684 1.88019 22.109 2.04619L19.9112 3.20814C19.5795 3.40605 19.3652 3.74442 19.3445 4.10833V6.43223C19.3652 6.79614 19.5795 7.13451 19.9112 7.33242L22.109 8.49437ZM20.5609 4.15302L22.6757 3.03576L24.7905 4.15302V6.38754L22.6757 7.5048L20.5609 6.38754V4.15302Z",
          fill: "#FDFDFD",
        },
      }),
      _c("path", {
        attrs: {
          d: "M6.08878 3.20811L3.89102 2.03978C3.53854 1.87378 3.11696 1.87378 2.75758 2.03978L0.56672 3.20811C0.234981 3.40603 0.0207337 3.73801 0 4.10831V6.43221C0.0207337 6.79611 0.234981 7.13448 0.56672 7.3324L2.75758 8.49435C3.11005 8.66034 3.53163 8.66034 3.89102 8.49435L6.08878 7.3324C6.42052 7.13448 6.62786 6.8025 6.6555 6.43859V4.10831C6.62786 3.73801 6.42052 3.40603 6.08878 3.20811ZM5.44604 6.38752L3.33121 7.50478L1.20946 6.38752V4.153L3.3243 3.03574L5.44604 4.153V6.38752Z",
          fill: "#FDFDFD",
        },
      }),
      _c("path", {
        attrs: {
          d: "M14.5481 21.3269C14.2717 21.1545 13.8985 21.2248 13.7119 21.4801L13.6082 21.6206V8.38586C13.6082 8.07941 13.3386 7.82404 13 7.82404C12.6613 7.82404 12.3918 8.07303 12.3918 8.38586V21.6206L12.2881 21.4801C12.1015 21.2248 11.7283 21.1545 11.4519 21.3269C11.1754 21.4993 11.0994 21.8441 11.286 22.0994L12.4955 23.7721C12.6061 23.9254 12.7996 24.0211 13 24.0211C13.2004 24.0211 13.3939 23.9254 13.5045 23.7721L14.714 22.0994C14.9006 21.8441 14.8246 21.4993 14.5481 21.3269Z",
          fill: "#FDFDFD",
        },
      }),
      _c("path", {
        attrs: {
          d: "M16.0271 14.0232V18.8243L15.9234 18.6838C15.7368 18.4284 15.3636 18.3582 15.0871 18.5306C14.8107 18.703 14.7347 19.0477 14.9213 19.3031L16.1307 20.9758C16.2413 21.129 16.4348 21.2248 16.6353 21.2248C16.8357 21.2248 17.0292 21.129 17.1398 20.9758L18.3492 19.3031C18.5358 19.0477 18.4598 18.6966 18.1834 18.5306C17.9069 18.3582 17.5268 18.4284 17.3471 18.6838L17.2434 18.8243V14.0296C17.2503 13.9977 17.2642 13.9658 17.2849 13.9402L17.3126 13.9147L21.3141 10.0458C21.5284 9.82233 21.5077 9.47758 21.2796 9.27328C21.0446 9.06898 20.6783 9.06898 20.4433 9.27328L16.4556 13.1358C16.186 13.372 16.034 13.6912 16.0271 14.0232Z",
          fill: "#FDFDFD",
        },
      }),
      _c("path", {
        attrs: {
          d: "M5.57744 9.27325C5.46686 9.15833 5.31481 9.0881 5.14894 9.08172C4.98307 9.07533 4.82411 9.13279 4.70662 9.23494C4.58913 9.33709 4.52002 9.48393 4.52002 9.63716C4.52002 9.79038 4.58913 9.93722 4.70662 10.0394L8.72204 13.9274C8.74277 13.953 8.7566 13.9849 8.77042 14.0168V16.9089L8.66675 16.7685C8.48015 16.5131 8.10694 16.4429 7.83049 16.6152C7.55404 16.7876 7.47802 17.1324 7.66462 17.3877L8.87409 19.0604C8.98467 19.2137 9.17818 19.3094 9.3786 19.3094C9.57903 19.3094 9.77254 19.2137 9.88312 19.0604L11.0926 17.3877C11.2792 17.1324 11.2032 16.7812 10.9267 16.6152C10.6503 16.4429 10.2702 16.5131 10.0905 16.7685L9.98679 16.9089V14.0232C9.97297 13.6912 9.82783 13.372 9.57212 13.1358L5.57744 9.27325Z",
          fill: "#FDFDFD",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }