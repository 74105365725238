import { RouteConfig } from 'vue-router';

import { appRoutes } from './routes/app';
import { authRoutes } from './routes/auth';
import { dataModelsRoutes } from './routes/data-models';
import { activitiesOverviewRoutes } from './routes/data-operations/activities-overview';
import { apiToStorageRoutes } from './routes/data-operations/api-to-storage';
import { fileUtilitiesRoutes } from './routes/data-operations/file-utilities';
import { storageToStorageRoutes } from './routes/data-operations/storage-to-storage';
import { storageToTablesRoutes } from './routes/data-operations/storage-to-tables';
import { tablesToTablesRoutes } from './routes/data-operations/tables-to-tables';
import { tableToStorageRoutes } from './routes/data-operations/table-to-storage';
import { vmLauncherRoutes } from './routes/data-operations/vm-launcher';
import { workflowRoutes } from './routes/data-operations/workflow';
import { contextRoutes } from './routes/data-operations/context';
import { XMLConversionRoutes } from './routes/data-operations/xml-conversion';
import { expectationsOverviewRoutes } from './routes/data-quality/expectations-overview';
import { freshnessRoutes } from './routes/data-quality/freshness';
import { settingsRoutes } from './routes/settings';

export const routes: RouteConfig[] = [
	...appRoutes,
	...authRoutes,
	...dataModelsRoutes,
	// TODO: Use nested routes for data-operations
	...activitiesOverviewRoutes,
	...apiToStorageRoutes,
	...fileUtilitiesRoutes,
	...storageToStorageRoutes,
	...storageToTablesRoutes,
	...tablesToTablesRoutes,
	...tableToStorageRoutes,
	...vmLauncherRoutes,
	...workflowRoutes,
	...contextRoutes,
	...XMLConversionRoutes,
	// END
	// TODO: Use nested routes for data-quality
	...expectationsOverviewRoutes,
	...freshnessRoutes,
	// END
	...settingsRoutes,
];
