import moment from 'moment';
import { Module } from 'vuex';
import { FilterState, RootState } from '@/types';

import { getters } from '@/store/modules/filters/getters';
import { actions } from '@/store/modules/filters/actions';
import { mutations } from '@/store/modules/filters/mutations';
import { ALL, Environments, Statuses } from '@/constants/data-operations/filters';
import { TaskCriticalities } from '@/constants/data-quality/filters';

export const state: FilterState = {
	accountFilterSelected: { account_name: 'All Accounts', id: '000000' },
	filteredAccounts: [],
	envFilterSelected: { label: 'All Env.', value: ALL },
	envFilters: [
		{ label: 'All Env.', value: Environments.All },
		{ label: 'Production', value: Environments.Prod },
		{ label: 'Pre-Production', value: Environments.PreProd },
		{ label: 'Staging', value: Environments.Staging },
		{ label: 'Development', value: Environments.Dev },
	],
	runStatusFilterSelected: { label: 'All Status', value: ALL },
	runStatusFilters: [
		{ label: 'All Status', value: Statuses.All },
		{ label: 'Success', value: Statuses.Success },
		{ label: 'Not Success', value: Statuses.NotSuccess },
		{ label: 'Failed', value: Statuses.Failed },
		{ label: 'Running', value: Statuses.Running },
		{ label: 'No Match', value: Statuses.NoMatch },
		{ label: 'Checked', value: Statuses.Checked },
	],
	confActivatedFilterSelected: { label: 'All Status', value: ALL },
	confActivatedFilters: [
		{ label: 'All Status', value: ALL },
		{ label: 'Activated', value: true },
		{ label: 'Disabled', value: false },
	],
	dateFilterSelected: { label: 'Today', value: 0 },
	dateFilters: [
		{ label: 'Today', value: 0 },
		{ label: 'Last 2 Days', value: 1 },
		{ label: 'Last 7 Days', value: 7 },
		{ label: 'Last 30 Days', value: 31 },
	],
	minDateFilter: moment().utc().startOf('day').toISOString(),
	expectations: {
		taskCriticality: {
			label: 'All Criticalities',
			value: TaskCriticalities.All,
		},
		taskCriticalityOptions: [
			{ label: 'All Criticalities', value: TaskCriticalities.All },
			{ label: 'Critical', value: TaskCriticalities.Critical },
			{ label: 'Warning', value: TaskCriticalities.Warning },
			{ label: 'Transparent', value: TaskCriticalities.Transparent },
		],
		passed: {
			label: 'Passed',
			value: true,
		},
		passedOptions: [
			{ label: 'All', value: ALL },
			{ label: 'Passed', value: true },
			{ label: 'Failed', value: false },
		],
	},
};

const namespaced: boolean = true;

export const filtersModule: Module<FilterState, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations,
};
