import Vue from 'vue';
import { getValue } from 'firebase/remote-config';

const checkRemoteConfigValue = (key: string, accountId: string) => {
	const remoteConfigValue = getValue(Vue.prototype.$remoteConfig, key).asString();
	return remoteConfigValue ? JSON.parse(remoteConfigValue)[accountId] : false;
};

export const userCanAccessXMLConversion = (accountId: string) => {
	return checkRemoteConfigValue('showWMLConversion', accountId);
};
