/* APP */
export const HOME = 'home';
export const NO_ACCOUNT = 'no-account';
export const NOT_FOUND = 'not-found';

/* AUTH */
export const SIGN_IN = 'sign-in';
export const RESET_PASSWORD = 'reset-password';
export const LOGOUT = 'logout';

/* DATA MODELS */
export const TABLES_LISTING = 'tables-listing';
export const DATA_TABLE_DETAILS = 'table-details';

/* DATA OPERATIONS */
export const ACTIVITIES_OVERVIEW_RUNS_LISTING = 'activities-overview-runs-listing';
export const ACTIVITIES_OVERVIEW_JOBS_LISTING = 'activities-overview-jobs-listing';
export const ACTIVITIES_OVERVIEW_CONFIGURATIONS_LISTING = 'activities-overview-configurations-listing';

export const API_TO_STORAGE_RUNS_LISTING = 'api-to-storage-runs-listing';
export const API_TO_STORAGE_RUNS_ITEM = 'api-to-storage-runs-item';
export const API_TO_STORAGE_CONFIGURATIONS_LISTING = 'api-to-storage-confs-listing';
export const API_TO_STORAGE_CONFIGURATIONS_ITEM = 'api-to-storage-conf-item';

export const FILE_UTILITIES_RUNS_LISTING = 'file-utilities-runs-listing';
export const FILE_UTILITIES_RUNS_ITEM = 'file-utilities-runs-item';
export const FILE_UTILITIES_CONFIGURATIONS_LISTING = 'file-utilities-confs-listing';
export const FILE_UTILITIES_CONFIGURATIONS_ITEM = 'file-utilities-conf-item';

export const STORAGE_TO_STORAGE_RUNS_LISTING = 'storage-to-storage-runs-listing';
export const STORAGE_TO_STORAGE_RUNS_ITEM = 'storage-to-storage-runs-item';
export const STORAGE_TO_STORAGE_CONFIGURATIONS_LISTING = 'storage-to-storage-confs-listing';
export const STORAGE_TO_STORAGE_CONFIGURATIONS_ITEM = 'storage-to-storage-conf-item';

export const STORAGE_TO_TABLES_RUNS_LISTING = 'storage-to-tables-runs-listing';
export const STORAGE_TO_TABLES_RUNS_ITEM = 'storage-to-tables-runs-item';
export const STORAGE_TO_TABLES_CONFIGURATIONS_LISTING = 'storage-to-tables-configurations-listing';
export const STORAGE_TO_TABLES_CONFIGURATIONS_ITEM = 'storage-to-tables-configurations-item';

export const TABLES_TO_TABLES_RUNS_LISTING = 'tables-to-tables-runs-listing';
export const TABLES_TO_TABLES_RUNS_ITEM = 'tables-to-tables-runs-item';
export const TABLES_TO_TABLES_CONFIGURATIONS_LISTING = 'tables-to-tables-confs-listing';
export const TABLES_TO_TABLES_CONFIGURATIONS_ITEM = 'tables-to-tables-conf-item';

export const TABLE_TO_STORAGE_RUNS_LISTING = 'table-to-storage-runs-listing';
export const TABLE_TO_STORAGE_RUNS_ITEM = 'table-to-storage-runs-item';
export const TABLE_TO_STORAGE_CONFIGURATIONS_LISTING = 'table-to-storage-confs-listing';
export const TABLE_TO_STORAGE_CONFIGURATIONS_ITEM = 'table-to-storage-confs-item';

export const VM_LAUNCHER_RUNS_LISTING = 'vm-launcher-runs-listing';
export const VM_LAUNCHER_RUNS_ITEM = 'vm-launcher-runs-item';
export const VM_LAUNCHER_CONFIGURATIONS_LISTING = 'vm-launcher-confs-listing';
export const VM_LAUNCHER_CONFIGURATIONS_ITEM = 'vm-launcher-confs-item';

export const WORKFLOW_STATUS_LISTING = 'workflow-status-listing';
export const WORKFLOW_STATUS_ITEM = 'workflow-status-item';
export const WORKFLOW_CONFIGURATIONS_LISTING = 'workflow-confs-listing';
export const WORKFLOW_CONFIGURATIONS_ITEM = 'workflow-confs-item';

export const CONTEXT_CONFIGURATIONS_LISTING = 'context-confs-listing';
export const CONTEXT_CONFIGURATIONS_ITEM = 'context-conf-item';

export const XML_CONVERSION_CONFIGURATIONS_LISTING = 'xml-conversion-confs-listing';
export const XML_CONVERSION_CONFIGURATIONS_ITEM = 'xml-conversion-conf-item';

/* DATA OPERATIONS */
export const EXPECTATIONS_OVERVIEW_LISTING = 'expectations-overview-runs-listing';
export const FRESHNESS_LISTING = 'freshness-listing';

/* SETTINGS */
export const PROFILE = 'user-profile';
export const USERS = 'users';
export const ACCOUNTS = 'accounts';

export default {
	NOT_FOUND,
	HOME,
	SIGN_IN,
	DATA_TABLE_DETAILS,
	FILE_UTILITIES_RUNS_LISTING,
	FILE_UTILITIES_RUNS_ITEM,
	FILE_UTILITIES_CONFIGURATIONS_LISTING,
	FILE_UTILITIES_CONFIGURATIONS_ITEM,
	STORAGE_TO_STORAGE_RUNS_LISTING,
	STORAGE_TO_STORAGE_RUNS_ITEM,
	STORAGE_TO_STORAGE_CONFIGURATIONS_LISTING,
	STORAGE_TO_STORAGE_CONFIGURATIONS_ITEM,
	STORAGE_TO_TABLES_RUNS_LISTING,
	STORAGE_TO_TABLES_RUNS_ITEM,
	STORAGE_TO_TABLES_CONFIGURATIONS_LISTING,
	STORAGE_TO_TABLES_CONFIGURATIONS_ITEM,
	TABLES_TO_TABLES_RUNS_LISTING,
	TABLES_TO_TABLES_RUNS_ITEM,
	TABLES_TO_TABLES_CONFIGURATIONS_LISTING,
	TABLES_TO_TABLES_CONFIGURATIONS_ITEM,
	TABLE_TO_STORAGE_RUNS_LISTING,
	TABLE_TO_STORAGE_RUNS_ITEM,
	TABLE_TO_STORAGE_CONFIGURATIONS_LISTING,
	TABLE_TO_STORAGE_CONFIGURATIONS_ITEM,
	VM_LAUNCHER_RUNS_LISTING,
	VM_LAUNCHER_RUNS_ITEM,
	VM_LAUNCHER_CONFIGURATIONS_LISTING,
	VM_LAUNCHER_CONFIGURATIONS_ITEM,
	WORKFLOW_STATUS_LISTING,
	WORKFLOW_STATUS_ITEM,
	WORKFLOW_CONFIGURATIONS_LISTING,
	WORKFLOW_CONFIGURATIONS_ITEM,
	PROFILE,
	USERS,
	ACCOUNTS,
};
