import { RouteConfig } from 'vue-router';

import { auth, hasAccount } from '@/router/middleware';
import { DATA_QUALITY } from '@/constants/router/paths-prefixes';
import { EXPECTATIONS_OVERVIEW } from '@/constants/data-quality/names';
import { RUNS } from '@/constants/data-operations/status';
import { EXPECTATIONS_OVERVIEW_LISTING } from '@/constants/router/routes-names';

export const expectationsOverviewRoutes: RouteConfig[] = [
	/**
	 * RUNS
	 */
	{
		path: `/${DATA_QUALITY}/${EXPECTATIONS_OVERVIEW.url}/${RUNS}`,
		name: EXPECTATIONS_OVERVIEW_LISTING,
		meta: { middleware: [auth, hasAccount], displayName: 'Expectation' },
		component: () =>
			import(
				/* webpackChunkName: "/data-quality/expectations-overview/runs/listing" */ '@/views/data-quality/expectations-overview/ListingView.vue'
			),
	},
];
