var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "accounts-selector d-flex justify-center align-center" },
    [
      _c("v-select", {
        staticClass: "selector",
        attrs: {
          items: _vm.getAccounts(),
          "item-text": "account_name",
          label: "Select an account",
          "no-data-text": "No accounts available",
          "return-object": "",
          "hide-details": "",
        },
        model: {
          value: _vm.selectedAccount,
          callback: function ($$v) {
            _vm.selectedAccount = $$v
          },
          expression: "selectedAccount",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }