export const DATA_MODELS = 'data-models';
export const DATA_OPERATIONS = 'data-operations';
export const DATA_QUALITY = 'data-quality';
export const SETTINGS = 'settings';

export default {
	DATA_MODELS,
	DATA_OPERATIONS,
	DATA_QUALITY,
	SETTINGS,
};
