import { IEasyFirestoreModule } from 'vuex-easy-firestore/types/declarations';
import { COLLECTION_REF, SYNC } from '@/constants/store/easy-firestore';
import { UPDATE_DOCUMENT_LOCALLY } from '@/store/modules/easy-firestore/_common/mutations';

export const fileUtilitiesConfs: IEasyFirestoreModule = {
	firestorePath: 'file-utilities-conf',
	firestoreRefType: COLLECTION_REF,
	moduleName: 'fileUtilitiesConfs',
	statePropName: 'data',
	sync: SYNC,
	mutations: { UPDATE_DOCUMENT_LOCALLY },
};
