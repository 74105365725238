var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app-bar",
    {
      staticClass: "px-2",
      attrs: {
        app: "",
        color: "#222630",
        height: "70px",
        "elevate-on-scroll": "",
      },
    },
    [
      _c("img", {
        attrs: {
          src: require("@/assets/img/app/logo-v2-white.svg"),
          alt: "Tailer Logo",
        },
      }),
      _vm.routeName
        ? _c("div", { staticClass: "route-name" }, [
            _vm.tabName
              ? _c("p", { staticClass: "tabs" }, [_vm._v(_vm._s(_vm.tabName))])
              : _vm._e(),
            _c("p", { staticClass: "name" }, [_vm._v(_vm._s(_vm.routeName))]),
          ])
        : _vm._e(),
      _c("v-spacer"),
      _c(
        "div",
        { staticClass: "account-selector" },
        [
          _c("v-select", {
            staticClass: "selector",
            attrs: {
              items: _vm.accountsAvalaible,
              solo: "",
              "hide-details": "",
              "hide-selected": "",
              dense: "",
              "item-text": "account_name",
              "return-object": "",
              rounded: "",
            },
            model: {
              value: _vm.selectedAccount,
              callback: function ($$v) {
                _vm.selectedAccount = $$v
              },
              expression: "selectedAccount",
            },
          }),
        ],
        1
      ),
      _c(
        "v-menu",
        {
          attrs: {
            "offset-y": "",
            origin: "center center",
            "nudge-bottom": 10,
            transition: "scale-transition",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g({ attrs: { icon: "", large: "" } }, on),
                    [
                      _vm.isAuthenticated &&
                      _vm.user &&
                      _vm.user.photoURL != null
                        ? _c("v-avatar", { attrs: { size: "38px" } }, [
                            _c("img", {
                              attrs: {
                                src: _vm.user.photoURL,
                                alt: _vm.user.displayName,
                              },
                            }),
                          ])
                        : _c(
                            "v-avatar",
                            { attrs: { size: "32px" } },
                            [_c("v-icon", [_vm._v("account_circle")])],
                            1
                          ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-list",
            _vm._l(_vm.userSettingsItems, function (item, index) {
              return _c(
                "v-list-item",
                {
                  key: index,
                  attrs: {
                    to: item.link,
                    disabled: item.disabled,
                    target: item.target,
                    rel: "noopener",
                  },
                },
                [
                  _c(
                    "v-list-item-action",
                    [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v(_vm._s(item.title))])],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }