var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-col",
    [
      _vm.isLoading
        ? _c("v-progress-linear", {
            attrs: { color: "complementary", indeterminate: "" },
          })
        : _c("v-treeview", {
            staticClass: "tree-view",
            attrs: {
              items: _vm.models,
              open: _vm.open,
              active: _vm.active,
              "active-class": "primary--text",
              dark: "",
              "open-on-click": "",
              transition: "",
              activatable: "",
            },
            on: {
              "update:open": function ($event) {
                _vm.open = $event
              },
              "update:active": function ($event) {
                _vm.active = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "label",
                fn: function ({ item, active }) {
                  return [
                    !item.children
                      ? _c(
                          "v-icon",
                          { attrs: { color: active ? "primary" : "white" } },
                          [_vm._v("table_chart")]
                        )
                      : _vm._e(),
                    _vm._v(" " + _vm._s(item.name) + " "),
                  ]
                },
              },
            ]),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }