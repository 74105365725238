import Vue from 'vue';
import { FirebaseError } from 'firebase/app';
// import { getApps, getApp, initializeApp, FirebaseError } from 'firebase/app';
import { initializeAnalytics } from 'firebase/analytics';
// import { initializeAuth } from 'firebase/auth';
import { initializeFirestore, enableMultiTabIndexedDbPersistence } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { initializePerformance } from 'firebase/performance';
import { getRemoteConfig, fetchAndActivate } from 'firebase/remote-config';
import { FirebaseOptions } from 'firebase/app';
import { AnyObject } from '@/types';
import { defaultConfig } from '@/remote-config/default-config';

// TODO: Remove when `vuex-easy-firestore` which uses compat mode will be removed
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// TODO: End remove

const options: FirebaseOptions = {
	apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
	appId: process.env.VUE_APP_FIREBASE_APP_ID,
	authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
	// databaseURL: `http://localhost:9000?ns=${process.env.VUE_APP_FIREBASE_PROJECT_ID}`,
	databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
	measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
	projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
};
const app = firebase.initializeApp(options); // TODO: Remove when `vuex-easy-firestore` which uses compat mode will be removed
// const app = getApps().length ? getApp() : initializeApp(options);
const remoteConfig = getRemoteConfig(app);
remoteConfig.defaultConfig = defaultConfig;
fetchAndActivate(remoteConfig);
Vue.prototype.$remoteConfig = remoteConfig;

async function initFirebase() {
	const firestore = initializeFirestore(app, {});
	const functions = getFunctions();

	functions.region = 'europe-west1';

	Vue.prototype.$httpsCallableFunction = (name: string, data: AnyObject = {}) => {
		return new Promise((resolve, reject) => {
			const callableFunction = httpsCallable(functions, name);

			callableFunction(data)
				.then(resolve)
				.catch((err: Error) => reject(err));
		});
	};

	initializeAnalytics(app);

	Vue.prototype.$perf = () => {
		return initializePerformance(app);
	};

	// TODO: Initialize ??
	// initializeAuth(app, { persistence: { type: 'SESSION' } });

	return new Promise((resolve, reject) => {
		enableMultiTabIndexedDbPersistence(firestore)
			.then(resolve)
			.catch((err: FirebaseError) => {
				if (err.code === 'failed-precondition') {
					// Multiple tabs open, persistence can only be enabled in one tab at a a time.
					reject(err);
				} else if (err.code === 'unimplemented') {
					// The current browser does not support all of the features required to enable persistence
					reject(err);
				}
			});
	});
}

export { firebase, initFirebase };
