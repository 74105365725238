export const ALL = 'ALL'; // TODO: Remove to use specific value on each type

export enum Environments {
	All = 'ALL',
	Prod = 'PROD',
	PreProd = 'PREPROD',
	Staging = 'STAGING',
	Dev = 'DEV',
}

export enum Statuses {
	All = 'ALL',
	Success = 'SUCCESS',
	NotSuccess = 'NOT_SUCCESS',
	Failed = 'FAILED',
	Running = 'RUNNING',
	NoMatch = 'NO_MATCH',
	Checked = 'CHECKED',
}
