
import { Component, Vue } from 'vue-property-decorator';
import {
	PROFILE,
	USERS,
	ACCOUNTS,
	ACTIVITIES_OVERVIEW_RUNS_LISTING,
	API_TO_STORAGE_RUNS_LISTING,
	FILE_UTILITIES_RUNS_LISTING,
	STORAGE_TO_STORAGE_RUNS_LISTING,
	STORAGE_TO_TABLES_RUNS_LISTING,
	TABLES_TO_TABLES_RUNS_LISTING,
	TABLE_TO_STORAGE_RUNS_LISTING,
	VM_LAUNCHER_RUNS_LISTING,
	WORKFLOW_STATUS_LISTING,
	CONTEXT_CONFIGURATIONS_LISTING,
	XML_CONVERSION_CONFIGURATIONS_LISTING,
	EXPECTATIONS_OVERVIEW_LISTING,
	FRESHNESS_LISTING,
} from '@/constants/router/routes-names';
import { Getter } from 'vuex-class';
import { User } from '@/types';
import { SUPER_ADMIN } from '@/constants/user/roles';
import ProjectOverviewIcon from '@/assets/icons/project-overview-icon.vue';
import { userCanAccessXMLConversion } from '@/remote-config/rules';
import DataOperationsIcon from '@/assets/icons/data-operations-icon.vue';
import DataQualityIcon from '@/assets/icons/data-quality-icon.vue';
import DataModelsIcon from '@/assets/icons/data-models-icon.vue';
import TreeView from '../data-models/TreeView.vue';
import FooterContent from '@/components/app/FooterContent.vue';
import router from '@/router';
import {
	API_TO_STORAGE,
	ACTIVITIES_OVERVIEW,
	FILE_UTILITIES,
	STORAGE_TO_STORAGE,
	STORAGE_TO_TABLES,
	TABLES_TO_TABLES,
	TABLE_TO_STORAGE,
	VM_LAUNCHER,
	WORKFLOW,
	CONTEXT,
	XML_CONVERSION,
} from '@/constants/data-operations/names';
import { EXPECTATIONS_OVERVIEW, FRESHNESS } from '@/constants/data-quality/names';

@Component({
	components: { ProjectOverviewIcon, DataOperationsIcon, DataQualityIcon, DataModelsIcon, TreeView, FooterContent },
})
export default class SideBar extends Vue {
	@Getter('filters/filteredAccounts') filteredAccounts!: Account[];
	@Getter('user/user') user!: User;

	dataOperationIsOpen: boolean = false;
	dataQualityIsOpen: boolean = false;
	dataModelsIsOpen: boolean = false;

	mounted() {
		this.openSelectedTabs();
	}

	openSelectedTabs() {
		this.$watch(
			() => this.$route,
			(route) => {
				if (route.path.includes('data-operations')) this.dataOperationIsOpen = true;
				if (route.path.includes('data-quality')) this.dataQualityIsOpen = true;
				if (route.path.includes('data-models')) this.dataModelsIsOpen = true;
				this.openSelectedTabs();
			}
		);
	}

	isCurrentPage(name: string) {
		return this.$route?.name?.includes(name);
	}

	get dataOperations() {
		return [
			{
				iconPath: () => import('@/assets/icons/activities-overview-icon.vue'),
				name: ACTIVITIES_OVERVIEW.displayName,
				redirectTo: ACTIVITIES_OVERVIEW_RUNS_LISTING,
				link: { name: ACTIVITIES_OVERVIEW_RUNS_LISTING, base: ACTIVITIES_OVERVIEW.url },
			},
			{
				iconPath: () => import('@/assets/icons/api-to-storage-icon.vue'),
				name: API_TO_STORAGE.displayName,
				link: { name: API_TO_STORAGE_RUNS_LISTING, base: API_TO_STORAGE.url },
			},
			{
				iconPath: () => import('@/assets/icons/context-icon.vue'), //TODO: update icon
				name: FILE_UTILITIES.displayName,
				link: { name: FILE_UTILITIES_RUNS_LISTING, base: FILE_UTILITIES.url },
			},
			{
				iconPath: () => import('@/assets/icons/storage-to-storage-icon.vue'),
				name: STORAGE_TO_STORAGE.displayName,
				link: { name: STORAGE_TO_STORAGE_RUNS_LISTING, base: STORAGE_TO_STORAGE.url },
			},
			{
				iconPath: () => import('@/assets/icons/storage-to-tables-icon.vue'),
				name: STORAGE_TO_TABLES.displayName,
				link: { name: STORAGE_TO_TABLES_RUNS_LISTING, base: STORAGE_TO_TABLES.url },
			},
			{
				iconPath: () => import('@/assets/icons/tables-to-tables-icon.vue'),
				name: TABLES_TO_TABLES.displayName,
				link: { name: TABLES_TO_TABLES_RUNS_LISTING, base: TABLES_TO_TABLES.url },
			},
			{
				iconPath: () => import('@/assets/icons/tables-to-storage-icon.vue'),
				name: TABLE_TO_STORAGE.displayName,
				link: { name: TABLE_TO_STORAGE_RUNS_LISTING, base: TABLE_TO_STORAGE.url },
			},
			{
				iconPath: () => import('@/assets/icons/vm-laucher-icon.vue'),
				name: VM_LAUNCHER.displayName,
				link: { name: VM_LAUNCHER_RUNS_LISTING, base: VM_LAUNCHER.url },
			},
			{
				iconPath: () => import('@/assets/icons/workflow-icon.vue'),
				name: WORKFLOW.displayName,
				link: { name: WORKFLOW_STATUS_LISTING, base: WORKFLOW.url },
			},
			{
				iconPath: () => import('@/assets/icons/context-icon.vue'),
				name: CONTEXT.displayName,
				link: { name: CONTEXT_CONFIGURATIONS_LISTING, base: CONTEXT.url },
			},
			{
				iconPath: () => import('@/assets/icons/api-to-storage-icon.vue'), //TODO: update icon
				name: XML_CONVERSION.displayName,
				link: { name: XML_CONVERSION_CONFIGURATIONS_LISTING, base: XML_CONVERSION.url },
				displayRule: userCanAccessXMLConversion(this.filteredAccounts[0].id),
			},
		];
	}

	get dataQuality() {
		return [
			{
				iconPath: () => import('@/assets/icons/freshness-icon.vue'),
				name: FRESHNESS.displayName,
				link: { name: FRESHNESS_LISTING, base: FRESHNESS.url },
			},
			{
				iconPath: () => import('@/assets/icons/expectation-icon.vue'),
				name: EXPECTATIONS_OVERVIEW.displayName,
				link: { name: EXPECTATIONS_OVERVIEW_LISTING, base: EXPECTATIONS_OVERVIEW.url },
			},
		];
	}

	get isSuperAdminRule() {
		return this.user.studioRoles === SUPER_ADMIN.roleCode;
	}

	get settings() {
		return [
			{
				name: 'Profile',
				iconPath: () => import('@/assets/icons/profile-icon.vue'),
				link: { name: PROFILE },
			},
			{
				name: 'Users',
				iconPath: () => import('@/assets/icons/users-icon.vue'),
				link: { name: USERS },
				displayRule: this.isSuperAdminRule,
			},
			{
				name: 'Account',
				iconPath: () => import('@/assets/icons/accounts-icon.vue'),
				link: { name: ACCOUNTS },
				displayRule: this.isSuperAdminRule,
			},
		];
	}
}
