import Vue from 'vue';
import axios, { AxiosRequestConfig } from 'axios';
import VueAxios from 'vue-axios';
import { getAuth, getIdToken, User } from 'firebase/auth';
import { AnyObject } from '@/types';

Vue.use(VueAxios, axios);

Vue.axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASE_URL;

const getCommonHeader = (accessToken: string) => ({
	Authorization: `Bearer ${accessToken}`,
});

Vue.prototype.$get = async function (url: string, requestConfig: AxiosRequestConfig) {
	const auth = getAuth();
	const user = auth.currentUser;
	return Vue.prototype.$http.get(url, {
		headers: getCommonHeader(await getIdToken(user as User)),
		...requestConfig,
	});
};

Vue.prototype.$post = async function (url: string, payload: AnyObject, requestConfig: AxiosRequestConfig) {
	const auth = getAuth();
	const user = auth.currentUser;
	return Vue.prototype.$http.post(
		url,
		{
			...payload,
			account: JSON.parse(localStorage.vuex).filters.filteredAccounts[0].id,
		},
		{
			headers: getCommonHeader(await getIdToken(user as User)),
			...requestConfig,
		}
	);
};
