import { RouteConfig } from 'vue-router';

import { auth, hasAccount } from '@/router/middleware';
import { DATA_OPERATIONS } from '@/constants/router/paths-prefixes';
import { ACTIVITIES_OVERVIEW } from '@/constants/data-operations/names';
import { RUNS, CONFIGURATIONS, JOBS } from '@/constants/data-operations/status';
import {
	ACTIVITIES_OVERVIEW_CONFIGURATIONS_LISTING,
	ACTIVITIES_OVERVIEW_JOBS_LISTING,
	ACTIVITIES_OVERVIEW_RUNS_LISTING,
} from '@/constants/router/routes-names';

const displayName = 'Activities Overview';

export const activitiesOverviewRoutes: RouteConfig[] = [
	/**
	 * RUNS
	 */
	{
		path: `/${DATA_OPERATIONS}/${ACTIVITIES_OVERVIEW.url}/${RUNS}`,
		name: ACTIVITIES_OVERVIEW_RUNS_LISTING,
		meta: { middleware: [auth, hasAccount], displayName },
		component: () =>
			import(
				/* webpackChunkName: "/data-operations/activities-overview/runs/listing" */ '@/views/data-operations/activities-overview/runs/ListingView.vue'
			),
	},
	/**
	 * JOBS
	 */
	{
		path: `/${DATA_OPERATIONS}/${ACTIVITIES_OVERVIEW.url}/${JOBS}`,
		name: ACTIVITIES_OVERVIEW_JOBS_LISTING,
		meta: { middleware: [auth, hasAccount], displayName },
		component: () =>
			import(
				/* webpackChunkName: "/data-operations/activities-overview/jobs/listing" */ '@/views/data-operations/activities-overview/jobs/ListingView.vue'
			),
	},
	/**
	 * CONFS
	 */
	{
		path: `/${DATA_OPERATIONS}/${ACTIVITIES_OVERVIEW.url}/${CONFIGURATIONS}`,
		name: ACTIVITIES_OVERVIEW_CONFIGURATIONS_LISTING,
		meta: { middleware: [auth, hasAccount], displayName },
		component: () =>
			import(
				/* webpackChunkName: "/data-operations/activities-overview/confs/listing" */ '@/views/data-operations/activities-overview/confs/ListingView.vue'
			),
	},
];
